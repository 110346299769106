import React, {useState} from 'react'
import tw, { css, styled } from 'twin.macro'

const EcoSwimTable = () => {
	const tableData = {
		headers: ['Ecoswim® Chlorinator Models','ES20','ES25','ES35'],
		tableRows:[
			{label: 'Maximum Pool Size*', values: ['60,000 L','80,000 L','100,000 L'], isAllTheSame: false},
			{label: 'Pump Control', values: ['Single or Variable Speed pool pump up to 8.5 amps'], isAllTheSame: true},
			{label: 'Optional upgrades', values: [<>Mineral Swim™ Dead Sea Minerals / <br/>Mineral Swim™ V2 Purfier Module</>], isAllTheSame: true},
			{label: 'Salt / mineral levels', values: ['3500 - 6500 ppm'], isAllTheSame: true},
			{label: 'Cell protection', values: ['Sensor controls chlorine output in excessive water conditions to help prevent unnecessary wear and tear'], isAllTheSame: true},
			{label: 'Power supply', values: ['220-240 V AC, 50-60 Hz, 10 amp'], isAllTheSame: true},
			{label: 'Warranty', values: ['5 years (3 years full + 2 years pro-rata)'], isAllTheSame: true},
		]
	}
	const [rowIndex, setRowIndex] = useState(0)
	return  (
					<table tw='text-center text-xs'>
					<thead  tw='whitespace-nowrap text-xs sm:text-sm text-white'>
						<tr tw='divide-x-2'>
							{tableData.headers.map((name, i) => {
									return <th key={i} tw='px-2 py-1 sm:px-4 sm:py-2 font-medium' style={{background:'#335979'}} >{name}</th>
							})}
						</tr>
					</thead>
					<tbody>
						{tableData.tableRows.map((x, i) => {
							return (
								<tr key={i}  css={[
									tw`px-2 py-1 sm:px-4 sm:py-2 font-medium`,
									(i % 2) && tw`bg-blue-200`,
								]}
								>
									<td tw='whitespace-nowrap font-medium' >{x.label}</td>
									{x.isAllTheSame ? (
										<td colSpan={tableData.headers.length - 1} tw='px-2 py-1 sm:px-4 sm:py-2 font-medium'>
											{x.values[0]}
										</td>
									) : 
										x.values.map((value, index) => <td key={index} tw='px-2 py-1 sm:px-4 sm:py-2 font-medium whitespace-nowrap' >
											{value}
										</td>
									)
								
								}
								</tr>
							)
						})}
					</tbody>
					</table>
	) 
}

export default EcoSwimTable
