import React, { useState, useEffect } from 'react'
import uniq from 'lodash.uniq'
import tw, { css, styled } from 'twin.macro'

import { TableSection, TableContainer, TableElement } from './styles'

const Table = ({ tableData, langCode }) => {
	const [data, setData] = useState(tableData)

	useEffect(() => {
		setData(tableData)
	}, [tableData])

	let newData = { ...data }
	const specdetails = newData.specification.map((x, y) => {
		return {
			...x,
			spec: uniq(x.spec),
		}
	})
	newData = {
		name: newData.name,
		country: newData.countryAvailability,
		specification: specdetails,
	}

	const countryFilter = newData.country.map((locale, i) => {
		const checkAvailability = locale.indexOf(langCode)
		return checkAvailability
	})
	const checkData = countryFilter.filter(x => x >= 0)
	return checkData.length > 0 ? (
		<TableSection>
			<TableContainer>
				<TableElement>
					<thead>
						<tr>
							<th></th>
							{newData.name.map((name, i) => {
								if (countryFilter[i] >= 0) {
									return <th key={i}>{name}</th>
								}
							})}
						</tr>
					</thead>
					<tbody>
						{newData.specification.map((x, i) => {
							if (x.specttl === 'Upgrade') {
								return ''
							}
							return (
								<tr key={i}>
									<td>{x.specttl}</td>
									{x.spec.map((specdetails, index) => {
										if (countryFilter[index] >= 0) {
											return (
												<td
													key={index}
													colSpan={x.ind[index]}
													css={!x.ind[index] && tw`hidden`}
												>
													{Array.isArray(specdetails) ? (
														<ul>
															{specdetails.map((spec, i) => {
																return (
																	<li key={i}>
																		<span>{spec}</span>
																	</li>
																)
															})}
														</ul>
													) : (
														specdetails
													)}
												</td>
											)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</TableElement>
			</TableContainer>
		</TableSection>
	) : (
		''
	)
}

export default Table
