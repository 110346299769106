import React from 'react';
import tw, { css } from 'twin.macro'
const ecoswimProcess = {
  "process1":[
    {
      "title": "ECOSWIM® REMOTE CONTROL",
      "content": "Easily program & customise your system",
      "reviseIndex":"0"
    },
    {
      "title": "ECOSWIM® CENTRAL CONTROL UNIT MODULE",
      "content": "Controls your Ecoswim® system",
      "reviseIndex":"1"
    }
  ],  
  "process2":[
    {
      "title": "ECOSWIM® GENERATOR MODULE",
      "content": "Converts minerals/salt into pure sanitiser",
      "reviseIndex":"2"
    },
    {
      "title": "MINERAL SWIM™ PURIFIER MODULE",
      "content": "Optional, delivers powerful ozone purification",
      "reviseIndex":"3"
    }
  ],
  "process3":[
    {
      "title": "FUTURE MODULE UPGRADE",
      "content": "Optional, future upgrades to enhance your system.",
      "reviseIndex":"4"
    }
  ]
}

export default ecoswimProcess;